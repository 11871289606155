//
//
//
//
//
//

export default {
    name: "BowWrapper",
};
