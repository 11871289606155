//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defaultBlockProps } from "~/helpers/data";

export default {
    name: "BowContent2Cols",
    props: {
        ...defaultBlockProps,

        ctaCol2: {
            default: null,
            type: Array,
        },

        textCol2: {
            default: null,
            type: String,
        },

        titleCol2: {
            default: null,
            type: Array,
        },
    },

    data: () => ({}),

    computed: {
        getViewBox() {
            return `0 0 ${window.screen.width} ${window.innerHeight}`;
        },
    },
};
